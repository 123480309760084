<template>
  <LiefengContent>
    <template v-slot:title>服务管理</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchForm"
        :label-colon="true"
        style="width: 624px"
        :inline="true"
      >
        <FormItem label="姓名">
          <Input
          :maxlength="20"
            v-model.trim="searchForm.name"
            placeholder="请输入姓名"
            style="width: 150px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
          :maxlength="20"
            v-model.trim="searchForm.mobile"
            placeholder="请输入手机号"
            style="width: 150px"
          ></Input>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search-outline"
          @click="search"
          style="margin-right: 10px"
          >查询</Button
        >
        <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
        <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchForm" :label-colon="true">
            <FormItem label="姓名">
              <Input :maxlength="20" v-model.trim="searchForm.name" placeholder="请输入姓名"></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
              :maxlength="20"
                v-model.trim="searchForm.mobile"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="档案编号">
              <Input
              :maxlength="20"
                v-model.trim="searchForm.archiveNum"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="所属社区">
              <Input
              :maxlength="20"
                v-model.trim="searchForm.orgName"
                placeholder="请输入所属社区"
              ></Input>
            </FormItem>
            <FormItem label="分类">
              <Select v-model="searchForm.difficultType">
                <Option
                  v-for="item in classificationList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="分级">
              <Select v-model="searchForm.difficultLevel">
                <Option
                  v-for="item in levelList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="跟进人">
              <Input
              :maxlength="20"
                v-model.trim="searchForm.staffName"
                placeholder="请输入跟进人"
              ></Input>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="search">查询</Button>
          </div>
        </Drawer>
      </Form>
      <Button
        type="error"
        @click="$router.push('/archivesindex')"
        icon="ios-arrow-back"
        >返回</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 设为停止服务弹窗 / 查看原因-->
      <LiefengModal
        :title="modelTitle"
        :value="modelStatus"
        height="400px"
        @input="modelStatusFn"
        class="add-change"
      >
        <template v-slot:contentarea>
          <Form :model="serviceData" :disabled="disabled" :label-colon="true" :label-width="80">
            <FormItem label="姓名" style="width: 100%;margin-bottom: 16px">
              <p>{{ serviceData.name }}</p>
            </FormItem>
            <FormItem label="档案编号" style="width: 100%;margin-bottom: 16px">
              <p>{{ serviceData.archiveNum }}</p>
            </FormItem>
            <FormItem label="停止原因" style="width: 100%;margin-bottom: 16px">
              <Select v-model="serviceData.stopCause">
                <Option :value="item.value" v-for="(item,index) in stopCauseList" :key="index">{{item.label}}</Option>
              </Select>
            </FormItem>
            <FormItem label="其他说明" v-if="serviceData.stopCause == 4" style="width: 100%">
              <Input v-model.trim="serviceData.remark" show-word-limit type="textarea" :maxlength="400" placeholder="请填写停止服务原因，不超过400字"></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              modelStatusFn(false);
            "
            style="margin-right: 10px"
            >关闭</Button
          >
          <Button type="primary" @click="saveArchives" v-if="!disabled">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/archivesstopservice")

import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import SeeDemand from "@/views/archives/childrens/seeDemand";

export default {
  data() {
    return {
      searchForm: {},
      selectQuery: false, //搜索抽屉
      //搜索分类
      classificationList: [
        { label: "低保低收入对象", value: "1" },
        { label: "特困人员", value: "2" },
        { label: "留守老年人", value: "3" },
        { label: "独居孤寡老人", value: "4" },
        { label: "困境儿童", value: "5" },
        { label: "贫困重度残疾人", value: "6" },
        { label: "其他", value: "7" },
      ],
      //搜索分级
      levelList: [
        { label: "一级", value: "1" },
        { label: "二级", value: "2" },
        { label: "三级", value: "3" },
        { label: "四级", value: "4" },
      ],
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "档案编号",
          key: "archiveNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          width: 150,
          align: "center",
        },
        {
          title: "所属社区",
          key: "orgName",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分类/人员类别",
          key: "difficultType",
          minWidth: 150,
          align: "center",
        },
        {
          title: "分级",
          key: "difficultLevel",
          width: 120,
          align: "center",
        },
        {
          title: "状态",
          key: "serviceStatus",
          width: 150,
          align: "center",
        },
        {
          title: "跟进人",
          key: "staffName",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 220,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "left",
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      textAlign: "center",
                      display: params.row.serviceStatus != "服务停止" ? "" : "none",
                    },
                    on: {
                      click: () => {
                        this.disabled = false;
                        this.modelTitle = "停止服务";
                        this.stopService(params.row.archiveId);
                      },
                    },
                  },
                  "设为停止服务"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      textAlign: "center",
                      marginRight: "10px",
                      display: params.row.serviceStatus == "服务停止" ? "" : "none",
                    },
                    on: {
                      click: () => {
                          this.cancelModelTip = false;
                          this.disabled = true;
                          this.modelTitle = "停止服务原因";
                          this.stopService(params.row.archiveId);
                      },
                    },
                  },
                  "查看原因"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      textAlign: "center",
                      display: params.row.serviceStatus == "服务停止" ? "" : "none",
                    },
                    on: {
                      click: () => {
                        this.recoveryService(params.row.archiveId);
                      },
                    },
                  },
                  "恢复服务状态"
                ),
              ]
            );
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      //停止服务
      modelTitle: "",
      serviceData: {},
      modelStatus: false,
      cancelModelTip: false,
      disabled: true,
      stopCauseList: []
    };
  },
  methods: {
    // 恢复
    recoveryService(id) {
       this.$Modal.confirm({
          title: "温馨提示",
          className: "vertical-center-modal",
          content: "<p>您正在恢复此条服务，是否继续</p>",
          onOk: () => {
            this.$post('/archives/api/pc/archiveStop/restart',{
              archiveId: id
            }).then(res => {
              if(res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "恢复成功"
                })
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize
                })
              }else {
                 this.$Message.error({
                  background: true,
                  content: res.desc
                })
              }
            })
          },
        });
    },
    // 查看
    seeService(id) {
      this.modelTitle = "停止服务原因";
      this.$Message.loading("正在加载数据...");
      this.$post("/archives/api/pc/archiveStop/queryByArchiveId", {
        archiveId: id,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.serviceData = {
            archiveNum: res.data.archiveNum,
            name: res.data.name
          }
          this.modelStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    //设为停止服务
    modelStatusFn(status) {
      if (!status && this.cancelModelTip && !this.disabled) {
        this.$Modal.confirm({
          title: "温馨提示",
          className: "vertical-center-modal",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.modelStatus = status;
          },
        });
      } else {
        this.modelStatus = status;
      }
    },
    stopService(id) {
      this.$Message.loading("正在加载数据...");
      this.$post("/archives/api/pc/archiveStop/queryByArchiveId", {
        archiveId: id,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.serviceData = res.data;
          this.modelStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    //保存
    saveArchives() {
      this.cancelModelTip = false;
      this.$post("/archives/api/pc/archiveStop/stop", {
        ...this.serviceData,
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功",
          });
          this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize
          })
          this.modelStatus = false;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },

    //重置
    reset() {
      this.searchForm = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.$post("/archives/api/pc/archiveStop/queryByPage", {
        ...this.searchForm,
        page: obj.page,
        pageSize: obj.pageSize,
        oemCode: parent.vue.oemInfo.oemCode,
         operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item, index) => {
              switch (item.difficultType) {
                case "1":
                  item.difficultType = "低保低收入对象";
                  break;
                case "2":
                  item.difficultType = "特困人员";
                  break;
                case "3":
                  item.difficultType = "留守老年人";
                  break;
                case "4":
                  item.difficultType = "独居孤寡老人";
                  break;
                case "5":
                  item.difficultType = "困境儿童";
                  break;
                case "6":
                  item.difficultType = "贫困重度残疾人";
                  break;
                case "7":
                  item.difficultType = "其他";
                  break;
              }
              switch (item.serviceStatus) {
                case "4":
                  item.serviceStatus = "服务停止";
                  break;
                  default: 
                   item.serviceStatus = "服务执行中";
                  break;
              }
              switch (item.difficultLevel) {
                case "1":
                  item.difficultLevel = "一级";
                  break;
                case "2":
                  item.difficultLevel = "二级";
                  break;
                case "3":
                  item.difficultLevel = "三级";
                  break;
                case "4":
                  item.difficultLevel = "四级";
                  break;
              }
            });
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.page = res.currentPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "STOP_CAUSE",
      }).then(res => {
        if(res.code == 200) {
          this.stopCauseList = res.dataList.map(item => {
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
        }
      })
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>

<style scoped lang="less">
/deep/.ivu-form-item {
  margin-bottom: 0;
  width: 210px;
}
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.add-change {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
</style>
